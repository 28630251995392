import React from "react"
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Box,
} from "@material-ui/core"
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined"
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined"
import LanguageIcon from "@material-ui/icons/Language"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined"
import CircleChecked from "@material-ui/icons/CheckCircleOutline"

const Requirements = ({ reqsData, translations }) => {
  var acommArray = []
  if (reqsData) {
    for (const [key, value] of Object.entries(reqsData?.accommodation)) {
      if (value) {
        var myString
        switch (key) {
          case "threeStar":
            myString = translations?.hotel3Stars
            break
          case "fourStar":
            myString = translations?.hotel4Stars
            break
          case "fiveStar":
            myString = translations?.hotel5Stars
            break
          case "budget":
            myString = translations?.budgetFriendly
            break
          case "apartments":
            myString = translations?.apartments
            break
          default:
            myString = ""
            break
        }
        acommArray = acommArray.concat(myString)
      }
    }
  }

  const accommString = acommArray.map((string, i) => {
    if (i === 0) {
      return string
    } else if (i > acommArray.length - 2) {
      return ` or ${string}`
    } else return `, ${string}`
  })

  var itineraryString = ""
  var mealsString = ""

  reqsData?.itinerary === "Relaxed"
    ? (itineraryString = translations?.relaxedRegime)
    : reqsData?.itinerary === "Balanced"
    ? (itineraryString = translations?.balancedRegime)
    : reqsData?.itinerary === "Tight"
    ? (itineraryString = translations?.tightRegime)
    : (itineraryString = "")

  reqsData?.meals === "Cheap"
    ? (mealsString = translations?.cheapMealRegime)
    : reqsData?.meals === "Balanced"
    ? (mealsString = translations?.balancedMealRegime)
    : reqsData?.meals === "Expensive"
    ? (mealsString = translations?.expensiveMealRegime)
    : (mealsString = "")

  const reqList = [
    {
      show: reqsData?.flightBooked === "yes" ? true : false,
      label: translations?.flightBooking,
    },
    {
      show: reqsData?.transportNeeded === "yes" ? true : false,
      label: translations?.transportation,
    },
    { show: true, label: itineraryString },
    { show: true, label: accommString },
    { show: true, label: mealsString },
    {
      show: reqsData?.guide === "yes" ? true : false,
      label: translations?.tourGuideSelected,
    },
    {
      show: reqsData?.siteseeing === "yes" ? true : false,
      label: translations?.sightSeeingSelected,
    },
    {
      show: reqsData?.insurance === "yes" ? true : false,
      label: translations?.travelInsuranceSelected,
    },
  ]
  return (
    <Box mt={4}>
      <Typography variant="overline">{translations?.requirements}</Typography>
      <List dense>
        {reqList.map(req =>
          req.show ? (
            <ListItem disableGutters dense>
              <ListItemIcon style={{ minWidth: "28px" }}>
                <CircleChecked fontSize="small" color="secondary" />
              </ListItemIcon>
              <ListItemText>{req.label}</ListItemText>
            </ListItem>
          ) : null
        )}
      </List>
    </Box>
  )
}

const CustomTourInfoBox = ({
  itineraryData,
  reqsData,
  translations,
  showReqs,
}) => {
  const citiesString = itineraryData?.cities.map(({ text_en }, index) => {
    if (index < 1) {
      return text_en
    } else if (index >= itineraryData?.cities.length - 1) {
      return " & " + text_en
    } else return ", " + text_en
  })

  const listData = [
    { icon: RoomOutlinedIcon, text: citiesString },
    {
      icon: CalendarTodayOutlinedIcon,
      text: `${itineraryData?.startDate.toDateString()} - ${itineraryData?.endDate.toDateString()}`,
    },
    {
      icon: LanguageIcon,
      text: itineraryData?.selectedLang,
    },
    {
      icon: PersonOutlineIcon,
      text: `${itineraryData?.adults} ${translations?.adults}, ${itineraryData?.childrenNum} ${translations?.children}, ${itineraryData?.infants} ${translations?.infants}`,
    },
    {
      icon: MonetizationOnOutlinedIcon,
      text: `${itineraryData?.budget *
        (itineraryData?.adults + itineraryData?.childrenNum)} € (€${
        itineraryData?.budget
      }/${translations?.perPerson})`,
    },
  ]
  return (
    <>
      <Box mt={4}>
        <Typography variant="overline">{translations?.tourSummary}</Typography>
        <List dense>
          {listData.map((item, i) => (
            <ListItem key={i} disableGutters dense>
              <ListItemIcon style={{ minWidth: "28px" }}>
                <SvgIcon fontSize="small" component={item.icon} />
              </ListItemIcon>
              <ListItemText>{item.text}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>

      {showReqs ? (
        <Requirements reqsData={reqsData} translations={translations} />
      ) : null}
    </>
  )
}

export default CustomTourInfoBox
