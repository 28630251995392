import React, { useCallback, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  Grid,
  Button,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core"
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import Layout from "../components/Layout"
import LocalizedLink from "../components/LocalizedLink"
import CustomTourInfoBox from "../components/CustomTour/CustomTourInfoBox"
import zhutravelTheme from "../components/Layout/zhutravelTheme"
import Axios from "axios"
import { BASE_URL } from "../config/api"
import { SEO } from "../components/SEO"

const mapTheme = createMuiTheme(zhutravelTheme, {
  palette: {
    secondary: {
      main: "#FF9486",
      contrastText: "#010204",
      dark: "#ff715f",
    },
  },
  overrides: {
    MuiListItem: {
      dense: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
})

const useStyles = makeStyles(theme => ({
  logo: {
    fontFamily: "MabryProBold",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: 0,
    lineHeight: "24px",
  },
}))

const QuoteRequested = ({ location, pageContext, data }) => {
  const classes = useStyles()
  const translations = useSelector(
    state => state?.locale?.localeObj?.pageData?.mapScreen
  )
  const { name, email, phone, wechat } = location?.state?.contactInfo
    ? location?.state?.contactInfo
    : { name: null, email: null, phone: null, wechat: null }
  const itineraryData = location?.state?.itineraryData
    ? location.state.itineraryData
    : null
  const reqsData = location?.state?.reqsData ? location.state.reqsData : null

  const sendEmail = useCallback(() => {
    const emailBody = `
      <h2>Customer informartion</h2>
      ${name && `<p><strong>Name: </strong>${name}</p>`}
      ${email && `<p><strong>Email: </strong>${email}</p>`}
      ${phone && `<p><strong>Phone: </strong>${phone}</p>`}
      ${wechat && `<p><strong>WeChat: </strong>${wechat}</p>`}
      <h2>Tour summary</h2>
        <p><strong>${
          itineraryData.cities.length > 0 ? "Cities" : "City"
        }</strong>
          <ul>
            ${itineraryData.cities.map(city => `<li>${city.text}</li>`)}
          </ul>
        </p>
        <p><strong>Dates: </strong>
          ${itineraryData.startDate.toDateString()} - ${itineraryData.endDate.toDateString()}
        </p>
        <p><strong>People:</strong>
          <ul>
            <li>Adults: ${itineraryData.adults}</li>
            <li>Children: ${itineraryData.childrenNum}</li>
            <li>Infants: ${itineraryData.infants}</li>
          </ul>
        </p>
        <p><strong>Budget per person: </strong>
          ${itineraryData.budget}
        </p>
      <h2>Requirements:</h2>
      <p>
        <ul>
          ${Object.entries(reqsData).map(([key, value]) => {
            return key === "accommodation"
              ? `<li>${key}: ${JSON.stringify(value)}</li>`
              : `<li>${key}: ${value}</li>`
          })}
        </ul>
      </p>
    `
    const body = {
      subject: "Custom tour enquiry",
      html: emailBody,
    }
    try {
      Axios.post(BASE_URL + "/email", body)
    } catch (err) {
      console.log(err)
    }
  }, [name, email, phone, wechat, itineraryData, reqsData])

  useEffect(() => {
    sendEmail()
  }, [sendEmail])

  return (
    <Layout isHeaderDisable location={location} pageContext={pageContext}>
      <SEO />
      <ThemeProvider theme={mapTheme}>
        <Container>
          <Hidden smDown>
            <Grid container spacing={3} style={{ marginTop: "60px" }}>
              <Grid item xs={1} />
              <Grid item xs={4} style={{ marginTop: "20px" }}>
                <Button
                  component={LocalizedLink}
                  to="/"
                  disableRipple
                  className={classes.logo}
                >
                  {translations?.zhutravel}
                </Button>
                <Box mt={5}>
                  <Typography variant="h2">Quote Requested</Typography>
                  <Typography>
                    Thanks for creating your tour with Zhutravel!
                  </Typography>
                  <Typography>We’ll send you a free quote to:</Typography>
                </Box>
                <Box mt={4}>
                  <Typography style={{ fontFamily: "MabryProRegular" }}>
                    {name}
                  </Typography>
                  <Typography>{email}</Typography>
                </Box>
                <Box mt={4}>
                  <CustomTourInfoBox
                    itineraryData={itineraryData}
                    reqsData={reqsData}
                    translations={translations}
                    showReqs
                  />
                </Box>
                <Typography variant="h3">
                  We will be in contact with you, this information has been sent
                  to us
                </Typography>
                <Box my={4}>
                  <Button to="/" component={LocalizedLink} variant="outlined">
                    Back to Zhutravel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={6}>
                <Img fluid={data.file.childImageSharp.fluid} />
              </Grid>
            </Grid>
          </Hidden>
        </Container>
        <Container style={{ backgroundColor: "#faf5ef" }}>
          <Hidden mdUp>
            <Grid container style={{ padding: "0" }}>
              <Grid item xs={1} />
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Button
                  component={LocalizedLink}
                  to="/"
                  disableRipple
                  className={classes.logo}
                >
                  {translations?.zhutravel}
                </Button>
                <Box mt={5}>
                  <Typography variant="h2">Quote Requested</Typography>
                  <Typography
                    style={{ marginTop: "10px", fontFamily: "MabryProRegular" }}
                  >
                    Thanks for creating your tour with Zhutravel!
                  </Typography>
                  <Grid item xs={12}>
                    <Img fluid={data.file.childImageSharp.fluid} />
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      fontFamily: "MabryProRegular",
                    }}
                  >
                    We’ll send you a free quote to:
                  </Typography>
                  <Typography style={{ fontFamily: "MabryProRegular" }}>
                    {name}
                  </Typography>
                  <Typography>{email}</Typography>
                </Box>
                <Box mt={4}>
                  <CustomTourInfoBox
                    itineraryData={itineraryData}
                    reqsData={reqsData}
                    translations={translations}
                    showReqs
                  />
                </Box>
                <Typography variant="h3" style={{ marginTop: "20px" }}>
                  We will be in contact with you, this information has been sent
                  to us
                </Typography>
                <Box my={4}>
                  <Button
                    to="/"
                    component={LocalizedLink}
                    variant="outlined"
                    style={{ backgroundColor: "#fff" }}
                  >
                    Back to Zhutravel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Hidden>
        </Container>
      </ThemeProvider>
    </Layout>
  )
}

export const data = graphql`
  query QuoteImageQuery {
    file(name: { eq: "OrderCompleted" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default QuoteRequested
